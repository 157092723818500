import React from "react";
import {Container} from "semantic-ui-react";
import Helmet from "react-helmet";
import withTrans from "../i18n/withTrans";

const IndexPage = ({t}) => (
    <div >
        <div style={{
            margin:"0 auto",
            width:"100vw",
            minHeight: '100vh',
            backgroundColor:"#FFF"
        }}>
            </div>
    </div>
);

export default withTrans(IndexPage);
